<script setup>
import { useToast } from '@/composables/use-ui';
const { showing, options } = useToast();
</script>

<template>
  <div v-if="showing" class="toast toast-top toast-center">
    <div class="alert shadow-xl" :class="options.type">
      <span class="text-white">{{ options.message }}</span>
    </div>
  </div>
</template>